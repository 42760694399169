import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    saveInvoice(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/billing/invoice/update/${elementData.id}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceItems(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/search/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_patient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceStatus(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/invoice_status', { params: { perPage: 50, page: 1 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx, params) {
      console.info('companyType', 'ARL')
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { params: { perPage: 100, page: 1, companyType: 'ARL', only_active: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/billing/invoice', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadCreditNotePdf(ctx, creditNoteId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/credit_notes/download_pdf/${creditNoteId}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendElement(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/billing/invoice/${elementData.id}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendElements(ctx, ids) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/billing/invoice/resend', { ids })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReport(ctx, params) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/api/v1/billing/rips_report`, {params: params.queryParams})
              .then(response => resolve(response))
              .catch(error => reject(error))
      })
    },
    getDebtPortfolio(ctx, params) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/api/v1/billing/debt_portfolio`, {params: params.queryParams})
              .then(response => resolve(response))
              .catch(error => reject(error))
      })
    }
  },
}
