<template>
  <div>
    <b-form
      @submit.prevent="search"
    >
      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-card>
          <b-card-body>
            <b-row>
              <!-- Number CN Field -->
              <b-col
                cols="6"
                xl="6"
              >
                <b-form-group 
                  label-for="number_cn"
                >
                  <feather-icon 
                    icon="StarIcon" 
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.number_cn') }}</span>
                  <validation-provider 
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.number_cn')"
                  >
                  <b-form-input 
                    id="number_cn" 
                    v-model="elementData.number_cn" 
                    maxlength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
              <!-- ARL Select List Field -->
              <b-col
                cols="6"
                md="6"
              >
                <template v-if="hasRole('ARL_USER')">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span>
                  <b-form-input
                    id="patient_identity"
                    :value="getArl()"
                    disabled
                  />
                </template>
                <template v-if="arlList.length > 0">
                  <b-form-group
                    label-for="arl"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(resourcesElementName + '.object.arl')"
                    >
                      <v-select
                        v-model="elementData.arl_id"
                        :options="arlList"
                        label="name"
                        value="id"
                        :reduce="option => option.id"
                        :placeholder="getPlaceholderValue(arlList)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </b-col>
            </b-row>
  
            <b-row>
                <!-- Patient Identity Number Field -->
                <b-col cols="6" xl="6">
                  <b-form-group label-for="patient_identity">
                    <feather-icon icon="StarIcon" class="mr-75"/>
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                    <validation-provider #default="{ errors }":name="$t(resourcesElementName + '.object.patient_identity')">
                      <b-form-input id="patient_identity" v-model="elementData.patient_identity" maxlength="20"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
  
                <!-- Patient Name Field -->
                <b-col cols="6" xl="6">
                  <b-form-group label-for="patient_name" >
                    <feather-icon icon="StarIcon" class="mr-75"/>
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                    <b-form-input id="name" v-model="elementData.patient_name" maxlength="240" />
                  </b-form-group>
                </b-col>
              </b-row>
  
              <b-row>
                <!-- From Date Field -->
                <b-col cols="6" md="6" lg="6" >
                  <date-picker
                    :element-required="false"
                    element-field="from"
                    :root-element-data="elementData"
                    :element-label="$t(resourcesElementName + '.object.from')"
                  />
                </b-col>
                <!-- Until Date Field -->
                <b-col cols="6" md="6" lg="6" >
                  <date-picker
                    :element-required="false"
                    element-field="until"
                    :root-element-data="elementData"
                    :element-label="$t(resourcesElementName + '.object.until')"
                  />
                </b-col>
              </b-row>
  
              <b-row>
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1"
                  :disabled="invalid"
                >
                  {{ $t('actions.search') }}
                </b-button>
              </b-row>
  
            </b-card-body>
          </b-card>
  
        </validation-observer>
      </b-form>
    </div>
  
</template>
  
<script>
import {
  BCard, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { hasRole } from '@/auth/utils'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    creditNumber: {
      bind(el, binding, vnode) {
        el.addEventListener('input', e => {
          const input = e.target
          input.value = input.value.replace(/[^0-9]/g, '').slice(0, 3)
          vnode.componentInstance.$emit('input', input.value)
        })
      },
    },
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    getArl() {
      if (this.userData && this.userData.arl) {
        return this.userData.arl.name
      }
      return ''
    },
    hasRole(roleKey) {
      return hasRole(roleKey)
    },
    validate(elementData) {
      const errors = []
      if ((!moment(elementData.from).isValid()) && (!moment(elementData.until).isValid())) {
        elementData.from = null
        elementData.until = null
      }
      if (elementData.from && (!elementData.until || !moment(elementData.until).isValid())) {
        errors.push(this.$t(`${this.resourcesElementName}.errors.date_until`))
      }
      if (elementData.until && (!elementData.from || !moment(elementData.from).isValid())) {
        errors.push(this.$t(`${this.resourcesElementName}.errors.date_from`))
      }
      if (moment(elementData.from).isAfter(moment(elementData.until))) {
        errors.push(this.$t(`${this.resourcesElementName}.errors.date_comparison`))
      }
      if (errors.length) {
        for (const error of errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(`${this.resourcesElementName}.errors.validations_title`),
              icon: 'SaveIcon',
              variant: 'danger',
              text: error,
            },
          })
        }
        return false
      }
      return true
    },
    search() {
      console.log('Searching with number_cn:', this.elementData.number_cn);
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if (this.validate(this.elementData)) {
            this.$parent.fetchElements()
          }
        }
      })
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  }
}
  </script>
  
  <style scoped>
  </style>
  