<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <h2 class="mb-1 text-primary">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.title') }}
        </h2>

        <b-form @submit.prevent="search">
          <validation-observer
            ref="elementForm"
            #default="{ invalid }">
            <b-row>
              <!-- From Date Field -->
              <b-col
                cols="6"
                md="3"
                lg="3">
                <date-picker
                  :element-required="true"
                  element-field="from"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.from')" />
              </b-col>
              <!-- Until Date Field -->
              <b-col
                cols="6"
                md="3"
                lg="3">
                <date-picker
                  :element-required="true"
                  element-field="until"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.to')" />
              </b-col>
              <b-col
                cols="6"
                md="3"
                lg="3">
                <b-form-group label-for="arl">
                  <template v-slot:label>
                    {{ $t('billing.search.field.arl') }}
                    <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('billing.search.field.arl')"
                    rules="required">
                    <v-select
                      v-model="elementData.arl_id"
                      :options="arlList"
                      label="name"
                      value="id"
                      :reduce="(option) => option.id"
                      :placeholder="$t('list.defaultOption')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                md="3"
                lg="3">
                <b-form-group label-for="rips_format">
                  <template v-slot:label>
                    {{ $t('billing.search.field.rips_format') }}
                    <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('billing.search.field.rips_format')"
                    rules="required">
                    <b-form-radio-group
                      v-model="elementData.rips_format"
                      :options="ripsFormatOptions"
                      class="mb-2"
                      stacked>
                    </b-form-radio-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="12">
                <div class="text-left">
                  <b-form-group>
                    <template v-slot:label>
                      <br />
                    </template>
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1"
                      :disabled="invalid">
                      {{ $t('actions.search') }}
                    </b-button>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
        <hr />

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />

            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :href="getUrl()"
            >
              <span class="text-nowrap">{{ $t('invoice.actions.export') }}</span>
            </b-button>

            <a
              v-if="downloadLink"
              id="view-report-button"
              class="btn btn-outline-primary"
              @click="getReport()"
              target="_blank"
            >
              <span class="text-nowrap">{{ $t('invoice.actions.download') }}</span>
            </a>
            <b-button
              v-if="hasErrorItems"
              @click="resendSelectedServices"
              :disabled="isResending">
              {{ isResending ? $t(RESOURCES_ELEMENT_NAME + '.actions.resending'): $t(RESOURCES_ELEMENT_NAME + '.actions.send')}}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="itemsFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <template v-slot:head(select)="data">
          <span>
            <b-form-checkbox
              id="toggle-select"
              v-model="allSelected"
              @change="toggleSelectAll" />
            <b-tooltip
              target="toggle-select"
              triggers="hover">
              <span class="align-middle ml-50"
                >Seleccionar / deseleccionar todo</span
              >
            </b-tooltip>
          </span>
        </template>

        <template #cell(select)="data">
          <b-form-checkbox-group
            id="checkbox-group"
            v-model="selected">
            <b-form-checkbox :value="data.item.id" />
          </b-form-checkbox-group>
        </template>
        <template #cell(in_helisa)="data">
          <b-badge
            :id="`status-${data.item.id}`"
            pill
            class="text-capitalize"
            :variant="`light-${resolveUserStatusVariant(data.item.in_helisa)}`">
            {{ $t(RESOURCES_ELEMENT_NAME + '.status.' + data.item.in_helisa) }}

            <!-- ? ERROR -->
            <b-tooltip
              v-if="data.item.in_helisa == 2"
              :target="`status-${data.item.id}`"
              :title="data.item.error_message"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </b-badge>
        </template>

        <template #cell(patient)="data">
          {{ getPatientName(data.item.patient) }}
        </template>

      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BForm,
} from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
import { mapState } from 'vuex'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import vSelect from 'vue-select'
import qs from 'qs'
import ElementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

const SIZE_LIST = [50, 100]

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      itemsFormatted: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {
        from: undefined,
        until: undefined,
        rips_format: 'json',
      },
      ripsFormatOptions: [
        { text: 'JSON', value: 'json' },
        { text: 'TXT', value: 'txt' }
      ],
      timer: null,
      selected: undefined,
      allElements: [],
      allSelected: true,
      downloadLink: undefined,
      arlList: [],
      isResending: false,
    }
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'invoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
    hasErrorItems() {
      return this.items.some(item => item.in_helisa === 2)
    },
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  async mounted() {
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.date = router.currentRoute.params.date
    this.arlList = await this.fetchARLs()

    this.tableColumns = [
      { key: 'select', label: '', thStyle: 'width: 100px;' },
      {
        key: 'arl.name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl`),
        sortable: true,
      },
      {
        key: 'number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'patient',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`),
        sortable: true,
      },
      {
        key: 'due_date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.due_date`),
        sortable: true,
      },
      {
        key: 'in_helisa',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.status`),
        sortable: true,
      },
    ]
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    if (data != null) {
      this.query = data.query
      this.elementData.from = data.from
      this.elementData.until = data.until
      this.elementData.arl_id = data.arl_id
      this.elementData.rips_format = data.rips_format || 'json' 
    }

    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleResendResponses(results) {
      results.forEach((result) => {
        if (result.status === 'OK') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.send.success`),
              icon: 'SendIcon',
              variant: 'success',
              text: `ID ${result.id}: ${this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.send.success`)}`
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.send.failure`),
              icon: 'SendIcon',
              variant: 'danger',
              text: `ID ${result.id}: ${result.message}`
            },
          })
        }
      })
    },
    async resendSelectedServices() {
      if (this.isResending) return

      this.isResending = true
      const selectedIds = this.items
        .filter(item => this.selected.includes(item.id) && item.in_helisa === 2)
        .map(item => item.id)
      
      if (selectedIds.length === 0) {
        console.error('No valid elements selected')
        this.isResending = false
        return
      }

      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/sendElements`
      
      try {
        const response = await store.dispatch(method, selectedIds)
        if (response.data && response.data.results) {
          this.handleResendResponses(response.data.results)
        }
      } catch (error) {
        console.error('Error al enviar elementos:', error)
      } finally {
        this.isResending = false
        await this.fetchElements()
      }
    },
    async fetchARLs() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCompanies`, {})
        .then((response) => {
          const list = response.data.items
          list.unshift({ id: null, name: this.$t('list.defaultOption') })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    search() {
      this.timer = setInterval(() => {
        this.fetchElements()
      }, 10000)
    },
    getPatientName(patient) {
      if (patient) {
        let name = patient.first_name
        name +=
          patient.second_name && patient.second_name != 'null'
            ? ` ${patient.second_name}`
            : ''
        name += ` ${patient.first_surname}`
        name +=
          patient.second_surname && patient.second_surname != 'null'
            ? ` ${patient.second_surname}`
            : ''
        return name
      }
      return ''
    },
    getUrl() {
      const uri_params = qs.stringify(
        { s: this.selected, rips_format: this.elementData.rips_format },
      { indices: false })
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/rips_report?${uri_params}`
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selected = [...this.allElements]
      } else {
        this.selected = []
      }
    },
    resolveUserStatusVariant(status) {
      if (status == 1) return 'success'
      if (status == 2) return 'danger'
      return 'secondary'
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    async send(elementData) {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/sendElement`
      const _self = this

      elementData.in_helisa = 0

      return store
        .dispatch(method, elementData)
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
              icon: 'SendIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          console.info(error.message)
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.failure`
              ),
              icon: 'SendIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
    },
    fetchElements() {
      this.elementData = {
        ...this.elementData,
        query: this.query,
        from: this.elementData.from,
        until: this.elementData.until,
        arl_id: this.elementData.arl_id,
      }
      sessionStorage.setItem(this.currentPath, JSON.stringify({
        ...this.elementData,
        query: this.query
      }))

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            arl_id: this.elementData.arl_id,
            from: this.elementData.from,
            until: this.elementData.until,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total

          if (this.selected === undefined) {
            this.selected = [...response.data.keys]
            this.allElements = response.data.keys
          }

          this.itemsFormatted = this.items.map((item) => ({
            ...item,
            date: moment(item.date).format('DD/MM/YYYY'),
            due_date: moment(item.due_date).format('DD/MM/YYYY'),
          }))
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
    getReport() {
      this.downloadLink = undefined

      const uri_params = qs.stringify(
        {
          queryParams: {
            selected: this.selected,
          },
        },
      )

      console.info(uri_params)

      /*
      store
          .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/getReport', {
            queryParams: {
              selected: this.selected,
            }
          })
          .then(response => {
            console.info('response.data:', response.data)
            this.downloadLink = response.data.path
          })
          .catch(() => {
            console.error('Error fetching elements')
          })
      */
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
