<template>
    <div>
      <search
        :element-data="elementData"
        :arl-list="arlList"
        :resources-element-name="RESOURCES_ELEMENT_NAME" />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ $t('table.search.show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50" />
              <label>{{ $t('table.search.entries') }}</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex justify-content-start mb-1 mb-md-0">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search.search')" />
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="export2excel">
                <span class="text-nowrap">{{ $t('list.export') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <vue-double-scrollbar v-if="tableDataLoaded">
          <b-table
            ref="refElementsListTable"
            class="position-relative"
            :items="itemsFormatted"
            :fields="tableColumns"
            primary-key="id"
            show-empty
            :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
            <!-- column: name -->
            <template #cell(name)="data">
              <b-badge
                pill
                class="text-capitalize">
                {{ data.item.name }}
              </b-badge>
            </template>
            <!-- Column: Status -->
            <!-- Column: actions -->
            <template #cell(actions)="data">
              <b-col class="flex flex-row flex-wrap">
                <a
                  :id="`download-button-${data.item.id}`"
                  class="btn btn-primary mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :href="getDownloadUrl(data.item.id)"
                  target="_blank">
                  <feather-icon icon="DownloadIcon" />
                </a>
                <!-- ? DETAILS -->
                <b-tooltip
                  :target="`download-button-${data.item.id}`"
                  :title="$t('actions.download_pdf')"
                  placement="top"
                  triggers="hover"
                  :noninteractive="true"
                  fallback-placement="counterclockwise">
                </b-tooltip>
              </b-col>
            </template>
          </b-table>
        </vue-double-scrollbar>
        <!-- Pagination -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-if="tableDataLoaded"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="total"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item">
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18" />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import * as XLSX from 'xlsx'
import Search from '@/views/billing/search_credit_note/Search.vue'
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import ElementStoreModule from './storeModule'
import Filters from './filters.vue'

export default {
  components: {
    Search,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: 'creditSearch',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      itemsFormatted: [],
      total: 1,
      perPage: 5,
      currentPage: this.$store.state.creditSearch.currentPage,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {},
      arlList: [],
      tableDataLoaded: false,
    }
  },
  computed: {
    ...mapState('creditSearch', ['searchData']),
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.$store.commit('creditSearch/setCurrentPage', val)
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
    elementData: {
      handler(val) {
        this.fetchElements()
      },
      deep: true,
    },
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'notes-credit-search'
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.arlList = await this.fetch_arls()
    console.log('ARL List on mounted:', this.arlList);
    this.tableColumns = [
      {
        key: 'number_cn',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number_cn`),
        sortable: true,
      },
      {
        key: 'invoice_number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.invoice_number`),
        sortable: true,
      },
      {
        key: 'patient_identity',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_identity`),
        sortable: true,
      },
      {
        key: 'patient_name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'arl_name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl_name`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]

    if (this.searchData) {
      this.elementData = this.searchData
    } else {
      this.elementData = {
        arl_id: null,
      }
    }
    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    ...mapMutations('creditSearch', ['setSearchData']),
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    async export2excel() {
      const filename = this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number_cn`)
      const result = await store.dispatch(
        `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements`,
        {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: this.query,
            number_cn: this.elementData.number_cn,
            invoice_number: this.elementData.invoice_number,
            patient_identity: this.elementData.patient_identity,
            patient_name: this.elementData.patient_name,
            date: this.elementData.date,
            arl_id: this.elementData.arl_id,
          },
        }
      )
      this.format(result.data.items)
      console.log(result.data.items)
      result.data.items.unshift(this.getHeader())
      const list = this.filterJson(result.data.items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    fetchElements() {
      this.setSearchData({ ...this.elementData })
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
            arl_id: this.elementData.arl_id,
            patient_identity: this.elementData.patient_identity,
            patient_name: this.elementData.patient_name,
            number_cn: this.elementData.number_cn,
            from: this.elementData.from,
            until: this.elementData.until,
          },
        })
        .then((response) => {
          console.log(response.data.items)
          this.items = response.data.items
          this.total = response.data.total
          this.tableDataLoaded = true

          this.itemsFormatted = this.items.map((item) => ({
            ...item,
            date: this.formatDate(item.date),
          }))
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
    formatDate(data) {
      let ret = moment(data, 'DD/MM/YYYY').format('DD/MM/YYYY')
      if (ret == 'Invalid date' || ret == 'Fecha inválida') {
        ret = moment(data).format('DD/MM/YYYY')
      }
      return ret == 'Invalid date' || ret == 'Fecha inválida' ? '' : ret
    },
    getDownloadUrl(creditNoteId) {
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/invoice_support/${creditNoteId}`
    },
    async fetch_arls() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_arls`, {})
        .then((response) => {
          const list = response.data.items
          list.unshift({
            id: null,
            name: this.$t(`${this.RESOURCES_ELEMENT_NAME}.search.defaultARL`),
          })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })  
    },
    }
  };
  </script>
  
  <style scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  