export default [
  {
    title: 'navigation.home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'navigation.admin',
    icon: 'SettingsIcon',
    roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'SUPPORT'],
    children: [
      {
        title: 'navigation.users',
        icon: 'UserIcon',
        route: 'apps-users-list',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      },
      {
        title: 'navigation.arl',
        icon: 'UserIcon',
        route: 'apps-arls-list',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      },
      {
        title: 'navigation.enterprise',
        icon: 'UserIcon',
        route: 'apps-enterprise-list',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.supplier',
        icon: 'UserIcon',
        route: 'apps-supplier-list',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      },
      {
        title: 'navigation.patient',
        icon: 'UserIcon',
        route: 'apps-patients-list',
        roles: ['SUPER_ADMINISTRATOR'],
      },
      {
        title: 'navigation.consult',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.injury_agent',
            icon: 'UserIcon',
            route: 'apps-injury-agent-list',
          },
          {
            title: 'navigation.injury_time',
            icon: 'UserIcon',
            route: 'apps-injury-time-list',
          },
          {
            title: 'navigation.compromised_liquid',
            icon: 'UserIcon',
            route: 'apps-compromised-liquid-list',
          },
          {
            title: 'navigation.ecp_purpose',
            icon: 'UserIcon',
            route: 'apps-ecp-purpose-list',
          },
          {
            title: 'navigation.edition_time',
            icon: 'UserIcon',
            route: 'apps-edition-time-edit',
          },
        ],
      },
      {
        title: 'navigation.service',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'SUPPORT'],
        children: [
          {
            title: 'navigation.bundle_name',
            icon: 'UserIcon',
            route: 'apps-bundle-name-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
          },
          {
            title: 'navigation.service_bundles',
            icon: 'UserIcon',
            route: 'apps-service-bundles-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
          },
          {
            title: 'navigation.service',
            icon: 'UserIcon',
            route: 'apps-services-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
          },
          {
            title: 'navigation.service_cost',
            icon: 'UserIcon',
            route: 'apps-service-cost-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
          },
          {
            title: 'navigation.stock_medicine',
            icon: 'UserIcon',
            route: 'apps-stock-medicine-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'SUPPORT'],
          },
        ],
      },
      {
        title: 'navigation.process',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.process_step',
            icon: 'UserIcon',
            route: 'apps-process-step-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
          {
            title: 'navigation.exam_result',
            icon: 'UserIcon',
            route: 'apps-exam-result-list',
            roles: ['SUPER_ADMINISTRATOR', 'MEDICO'],
          },
          {
            title: 'navigation.diagnosis',
            icon: 'UserIcon',
            route: 'apps-diagnosis-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
          {
            title: 'navigation.process_noti_type',
            icon: 'UserIcon',
            route: 'apps-process-noti-type-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
          {
            title: 'navigation.process_noti_medium',
            icon: 'UserIcon',
            route: 'apps-process-noti-medium-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
          {
            title: 'navigation.config_notification',
            icon: 'UserIcon',
            route: 'apps-config-notification-list',
            roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
          },
          {
            title: 'navigation.date_type',
            icon: 'UserIcon',
            route: 'apps-date-type-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
          {
            title: 'navigation.process_messages',
            icon: 'UserIcon',
            route: 'apps-process-messages-list',
            roles: ['SUPER_ADMINISTRATOR'],
          },
        ],
      },
      {
        title: 'navigation.logbook',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.logbook_reason',
            icon: 'UserIcon',
            route: 'apps-logbook-reason-list',
          },
          {
            title: 'navigation.logbook_result',
            icon: 'UserIcon',
            route: 'apps-logbook-result-list',
          },
          {
            title: 'navigation.logbook_medium',
            icon: 'UserIcon',
            route: 'apps-logbook-medium-list',
          },
        ],
      },
      {
        title: 'navigation.digital_file',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.documental_type',
            icon: 'UserIcon',
            route: 'apps-documental-type-list',
          },
          {
            title: 'navigation.folder_type',
            icon: 'UserIcon',
            route: 'apps-folder-type-list',
          },
        ],
      },
      {
        title: 'navigation.medicine',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.administration_route',
            icon: 'UserIcon',
            route: 'apps-administration-route-list',
          },
          {
            title: 'navigation.units_of_measure',
            icon: 'UserIcon',
            route: 'apps-units-of-measure-list',
          },
          {
            title: 'navigation.reference_unit',
            icon: 'UserIcon',
            route: 'apps-reference-unit-list',
          },
          {
            title: 'navigation.dosage_form',
            icon: 'UserIcon',
            route: 'apps-dosage-form-list',
          },
          {
            title: 'navigation.delivery_unit',
            icon: 'UserIcon',
            route: 'apps-delivery-unit-list',
          },
          {
            title: 'navigation.medicine_group',
            icon: 'UserIcon',
            route: 'apps-medicine-group-list',
          },
        ],
      },
      {
        title: 'navigation.case',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.case_type',
            icon: 'UserIcon',
            route: 'apps-case-type-list',
          },
          {
            title: 'navigation.case_status',
            icon: 'UserIcon',
            route: 'apps-case-status-list',
          },
          {
            title: 'navigation.case_tracking_type',
            icon: 'UserIcon',
            route: 'apps-case-tracking-type-list',
          },
        ],
      },
      {
        title: 'navigation.company',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.company_type',
            icon: 'UserIcon',
            route: 'apps-company-type-list',
          },
          {
            title: 'navigation.supplier_type',
            icon: 'UserIcon',
            route: 'apps-supplier-type-list',
          },
        ],
      },
      {
        title: 'navigation.freezer',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.sample_status',
            icon: 'UserIcon',
            route: 'apps-sample-status-list',
          },
          {
            title: 'navigation.freezer_status',
            icon: 'UserIcon',
            route: 'apps-freezer-status-list',
          },
          {
            title: 'navigation.delivery_status',
            icon: 'UserIcon',
            route: 'apps-delivery-status-list',
          },
          {
            title: 'navigation.reception_status',
            icon: 'UserIcon',
            route: 'apps-reception-status-list',
          },
          {
            title: 'navigation.freezer_next_actions',
            icon: 'UserIcon',
            route: 'apps-freezer-next-actions-list',
          },
        ],
      },
      {
        title: 'navigation.parameter',
        icon: 'SettingsIcon',
        roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
        children: [
          {
            title: 'navigation.eps',
            icon: 'UserIcon',
            route: 'apps-eps-list',
          },
          {
            title: 'navigation.jobs',
            icon: 'UserIcon',
            route: 'apps-job-list',
          },
          {
            title: 'navigation.relationship',
            icon: 'UserIcon',
            route: 'apps-relationship-list',
          },
          {
            title: 'navigation.document_type',
            icon: 'UserIcon',
            route: 'apps-document-type-list',
          },
          {
            title: 'navigation.service_type',
            icon: 'UserIcon',
            route: 'apps-service-type-list',
          },
          {
            title: 'navigation.consult_type',
            icon: 'UserIcon',
            route: 'apps-consult-type-list',
          },
          {
            title: 'navigation.country',
            icon: 'UserIcon',
            route: 'apps-country-list',
          },
          {
            title: 'navigation.population_zone',
            icon: 'UserIcon',
            route: 'apps-population-zone-list',
          },
          {
            title: 'navigation.sex',
            icon: 'UserIcon',
            route: 'apps-sex-list',
          },
          {
            title: 'navigation.genre',
            icon: 'UserIcon',
            route: 'apps-genre-list',
          },
          {
            title: 'navigation.employment',
            icon: 'UserIcon',
            route: 'apps-employment-list',
          },
          {
            title: 'navigation.contact_type',
            icon: 'UserIcon',
            route: 'apps-contact-type-list',
          },
          {
            title: 'navigation.boolean',
            icon: 'UserIcon',
            route: 'apps-boolean-list',
          },
          {
            title: 'navigation.booleanNA',
            icon: 'UserIcon',
            route: 'apps-booleanNA-list',
          },
          {
            title: 'navigation.occupation',
            icon: 'UserIcon',
            route: 'apps-occupation-list',
          },
          {
            title: 'navigation.templates',
            icon: 'UserIcon',
            route: 'apps-templates-list',
          },
          {
            title: 'navigation.holiday_types',
            icon: 'UserIcon',
            route: 'apps-holiday-type-list',
          },
          {
            title: 'navigation.holidays',
            icon: 'UserIcon',
            route: 'apps-holidays-list',
          },
          {
            title: 'navigation.billingPaymentTerm',
            icon: 'UserIcon',
            route: 'apps-billing-payment-term-list',
          },
          {
            title: 'navigation.invoiceGroup',
            icon: 'UserIcon',
            route: 'apps-invoice-group-list',
          },
          {
            title: 'navigation.invoiceStatus',
            icon: 'UserIcon',
            route: 'apps-invoice-status-list',
          },
        ],
      },
    ],
  },
  {
    title: 'navigation.case',
    icon: 'LayersIcon',
    roles: ['INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC', 'SUPER_ADMINISTRATOR', 'COORDINATOR_REFRIGERATORS', 'AGENDA_COORDINATOR', 'CONSULTATION_COORDINATOR', 'ARL_USER', 'ADMINISTRATIVE', 'COORDINADOR_FACTURAS'],
    children: [
      {
        title: 'navigation.import',
        icon: 'UploadIcon',
        route: 'apps-cases-upload',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS'],
      },
      {
        title: 'navigation.received',
        icon: 'InboxIcon',
        route: 'apps-case-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS'],
      },
      {
        title: 'navigation.not_located',
        icon: 'InboxIcon',
        route: 'apps-case-not-located-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS'],
      },
      {
        title: 'navigation.support_positives',
        icon: 'InboxIcon',
        route: 'apps-case-support-positives-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'SUPPORT', 'FOLLOWUP'],
      },
      {
        title: 'navigation.support_transcript_pending',
        icon: 'InboxIcon',
        route: 'apps-case-support-transcript-pending-list',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.support_reschedule',
        icon: 'InboxIcon',
        route: 'apps-case-support-reschedule-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'SUPPORT', 'COORDINATOR_REFRIGERATORS'],
      },
      {
        title: 'navigation.support_closed',
        icon: 'InboxIcon',
        route: 'apps-case-support-closed-list',
        roles: ['INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC', 'SUPER_ADMINISTRATOR', 'COORDINATOR_REFRIGERATORS', 'AGENDA_COORDINATOR', 'CONSULTATION_COORDINATOR', 'ARL_USER', 'ADMINISTRATIVE'],
      },
      {
        title: 'navigation.support_uncomplete_exams',
        icon: 'InboxIcon',
        route: 'apps-case-support-pending-exams-list',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.support_initial_pending_exams',
        icon: 'InboxIcon',
        route: 'apps-case-support-initial-pending-exams-list',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.complete_results',
        icon: 'InboxIcon',
        route: 'apps-case-support-complete-results-list',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.following_without_labs',
        icon: 'InboxIcon',
        route: 'apps-case-support-following-without-labs-list',
        roles: ['SUPER_ADMINISTRATOR', 'FOLLOWUP', 'SUPPORT'],
      },
      {
        title: 'navigation.vaccine_schema',
        icon: 'InboxIcon',
        route: 'vaccine-schema-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC'],
      },
      {
        title: 'navigation.search',
        icon: 'SearchIcon',
        route: 'apps-cases-search',
        roles: ['INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC', 'SUPER_ADMINISTRATOR', 'COORDINATOR_REFRIGERATORS', 'AGENDA_COORDINATOR', 'CONSULTATION_COORDINATOR', 'ARL_USER', 'ADMINISTRATIVE', 'COORDINADOR_FACTURAS'],
      },
    ],
  },
  {
    title: 'navigation.dates',
    icon: 'CalendarIcon',
    roles: ['SUPER_ADMINISTRATOR', 'MEDIC', 'AGENDA_COORDINATOR'],
    children: [
      {
        title: 'navigation.availability',
        icon: 'BookOpenIcon',
        route: 'apps-availabilities-list',
        roles: ['SUPER_ADMINISTRATOR', 'AGENDA_COORDINATOR'],
      },
      {
        title: 'navigation.calendar',
        icon: 'CalendarIcon',
        route: 'apps-schedules',
        roles: ['SUPER_ADMINISTRATOR', 'MEDIC'],
      },
      {
        title: 'navigation.my_schedule',
        icon: 'AirplayIcon',
        route: 'apps-my-schedule-list',
        roles: ['SUPER_ADMINISTRATOR', 'MEDIC'],
      },
    ],
  },
  {
    title: 'navigation.billing',
    icon: 'CreditCardIcon',
    roles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATIVE', 'COORDINADOR_FACTURAS'],
    children: [
      {
        title: 'navigation.billing',
        icon: 'CreditCardIcon',
        route: 'apps-billing-billing',
      },
      {
        title: 'navigation.paymentTerm',
        icon: 'CalendarIcon',
        route: 'apps-billing-payment-term',
      },
      {
        title: 'navigation.consecutiveInvoice',
        icon: 'HashIcon',
        route: 'apps-billing-consecutive-invoice',
      },
      {
        title: 'navigation.consecutiveCreditNote',
        icon: 'HashIcon',
        route: 'apps-billing-consecutive-credit-note',
      },
      {
        title: 'navigation.serviceAccount',
        icon: 'HashIcon',
        route: 'apps-billing-service-account',
      },
      {
        title: 'navigation.invoiceGroupSearch',
        icon: 'SearchIcon',
        route: 'apps-billing-invoice-search',
      },
      {
        title: 'navigation.invoiceConsult',
        icon: 'SearchIcon',
        route: 'apps-billing-invoice-consult',
      },
      {
        title: 'navigation.import_billing',
        icon: 'SearchIcon',
        route: 'apps-billing-payment-upload',
      },
      {
        title: 'navigation.creditnoteSearch',
        icon: 'SearchIcon',
        route: 'apps-billing-creditnote-search',
      },
    ],
  },
  {
    title: 'navigation.freezer',
    icon: 'SettingsIcon',
    roles: ['SUPER_ADMINISTRATOR', 'FOLLOWUP'],
    children: [
      {
        title: 'navigation.freezer_pending',
        icon: 'UserIcon',
        route: 'freezer-pending-list',
      },
      {
        title: 'navigation.freezer_scheduled',
        icon: 'UserIcon',
        route: 'freezer-scheduled-list',
      },
      {
        title: 'navigation.import_guides',
        icon: 'UserIcon',
        route: 'freezer-upload',
      },
    ],
  },
  {
    title: 'navigation.services_following',
    icon: 'CodesandboxIcon',
    roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'SUPPORT', 'FOLLOWUP', 'ADMINISTRATIVE'],
    children: [
      {
        title: 'navigation.arl_codes',
        icon: 'CodepenIcon',
        route: 'arl-codes-list',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'FOLLOWUP', 'SUPPORT', 'ADMINISTRATIVE'],
      },
      {
        title: 'navigation.arl_codes_importing',
        icon: 'CodepenIcon',
        route: 'arl-codes-upload',
        roles: ['SUPER_ADMINISTRATOR', 'INITIALS', 'FOLLOWUP'],
      },
      {
        title: 'navigation.medicine_delivery',
        icon: 'CodepenIcon',
        route: 'medicine-delivery-list',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
      {
        title: 'navigation.auth_pending',
        icon: 'CodepenIcon',
        route: 'auth-pending-list',
        roles: ['SUPER_ADMINISTRATOR', 'FOLLOWUP'],
      },
      {
        title: 'navigation.auth_final',
        icon: 'CodepenIcon',
        route: 'auth-final-list',
        roles: ['SUPER_ADMINISTRATOR', 'FOLLOWUP'],
      },
      {
        title: 'navigation.arl_codes_search',
        icon: 'SearchIcon',
        route: 'apps-arl-codes-search',
        roles: ['INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC', 'SUPER_ADMINISTRATOR', 'COORDINATOR_REFRIGERATORS', 'AGENDA_COORDINATOR', 'CONSULTATION_COORDINATOR', 'ARL_USER', 'ADMINISTRATIVE'],
      },
      {
        title: 'navigation.arl_packages_search',
        icon: 'SearchIcon',
        route: 'apps-arl-packages-search',
        roles: ['INITIALS', 'SUPPORT', 'FOLLOWUP', 'MEDIC', 'SUPER_ADMINISTRATOR', 'COORDINATOR_REFRIGERATORS', 'AGENDA_COORDINATOR', 'CONSULTATION_COORDINATOR', 'ARL_USER', 'ADMINISTRATIVE'],
      },
    ],
  },
  {
  title: 'navigation.reports',
    icon: 'LayersIcon',
    roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
    children: [
      {
        title: 'Informes de gestión',
        icon: 'CodepenIcon',
        route: 'apps-reports',
        roles: ['SUPER_ADMINISTRATOR', 'SUPPORT'],
      },
    ],
  },
]
