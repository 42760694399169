import axios from '@axios'

export default {
  namespaced: true,
  state: {
    searchData: null,
    currentPage: 1,
  },
  getters: {},
  mutations: {
    setSearchData(state, data) {
        state.searchData = data
    },
    setCurrentPage(state, page) {
        state.currentPage = page
    },
  },
  actions: {
    fetch_elements(ctx, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/v1/billing/credit_notes`, {params: params.queryParams})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
    fetch_arls(ctx) {
        return new Promise((resolve, reject) => {
          axios
            .get('/api/v1/cases/company', {
              params: {
                perPage: 500, page: 1, companyType: 'ARL', only_active: true,
              },
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetch_credit_note_pdf(ctx, {id}) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/v1/billing/credit_notes/download_pdf/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
  }
}
