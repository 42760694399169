<template>
  <b-form @submit.prevent="save">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">Orden médica de consulta</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="consultType">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">Próxima consulta</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="consultType"
                  rules="required">
                  <v-select
                    v-model="consultData.nextConsultId"
                    :options="internalTrackingType"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onNextConsultChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="serviceType">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">Servicio</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="serviceType"
                  rules="required">
                  <v-select
                    v-model="consultData.service.service_id"
                    :options="medicalConsultServicesList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <date-picker
                :element-required="requiresFollow"
                element-field="proposed_date"
                :root-element-data="consultData"
                :element-label="
                  $t(consultResourcesElementName + '.object.proposed_date')
                " />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12">
              <b-form-group label-for="observations">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">Observaciones</span>
                <validation-provider
                  #default="{ errors }"
                  name="observations">
                  <b-form-textarea
                    id="observations"
                    v-model="consultData.observations"
                    maxlength="500" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-button
              id="cancel-button"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="hide_modal()">
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid">
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-form>
</template>
<script>
import {
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import DatePicker from '@/views/core/DatePicker.vue'

export default {
  components: {
    DatePicker,
    BFormTextarea,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    consultData: {
      type: Object,
      required: true,
    },
    caseTrackingType: {
      type: Array,
      required: true,
    },
    medicalConsultServicesList: {
      type: Array,
      required: true,
    },
    finishedConsults: {
      type: Array,
      required: true,
    },
    followDate: {
      type: String,
      required: false,
    },
    requiresFollow: {
      type: Boolean,
      required: false,
    },
    followType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      consultResourcesElementName: 'consult',
      internalTrackingType: [],
    }
  },
  watch: {},
  mounted() {
    this.internalTrackingType = this.getInternalTrackingTypes()
    // proposed date is required if followDate is not null
    const formattedFollowDate = this.followDate ? this.followDate : ''
    if (!this.consultData.proposed_date) {
      if (this.followDate) {
        this.consultData.proposed_date = this.followDate;
      } else {
        this.consultData.proposed_date = new Date();
      }
    }
  },
  methods: {
    onNextConsultChange() {
      this.consultData.nextConsult = this.internalTrackingType.find(
        (element) => element.id == this.consultData.nextConsultId
      )
    },
    hide_modal() {
      this.$root.$emit('bv::hide::modal', 'modal-medical-consult')
    },
    getListPostFinishCosunsult(consult) {
      let types = []
      switch (consult) {
        case 'INITIAL_VALORATION':
          types = [
            this.$t('list.defaultOption'),
            'FIRST_FOLLOW',
            'ADDITIONAL',
            'EVOLUTION',
          ]
          break
        case 'FIRST_FOLLOW':
          types = [
            this.$t('list.defaultOption'),
            'SECOND_FOLLOW',
            'ADDITIONAL',
            'EVOLUTION',
          ]
          break
        case 'SECOND_FOLLOW':
          types = [
            this.$t('list.defaultOption'),
            'THIRD_FOLLOW',
            'ADDITIONAL',
            'EVOLUTION',
          ]
          break
        case 'THIRD_FOLLOW':
          types = [
            this.$t('list.defaultOption'),
            'FOURTH_FOLLOW',
            'ADDITIONAL',
            'EVOLUTION',
          ]
          break
        case 'FOURTH_FOLLOW':
          types = [this.$t('list.defaultOption'), 'ADDITIONAL', 'EVOLUTION']
          break
        default:
          types = [
            this.$t('list.defaultOption'),
            'FIRST_FOLLOW',
            'SECOND_FOLLOW',
            'THIRD_FOLLOW',
            'FOURTH_FOLLOW',
            'ADDITIONAL',
            'EVOLUTION',
          ]
      }
      return this.caseTrackingType.filter((element) =>
        types.includes(element.key)
      )
    },
    getInternalTrackingTypes() {
      const end_consult = this.finishedConsults?.length
        ? this.finishedConsults[this.finishedConsults?.length - 1].key
        : null
      let select_consults = []
      switch (this.followType) {
        case 'ADDITIONAL':
          select_consults = this.getListPostFinishCosunsult(end_consult)
          break
        case 'EVOLUTION':
          select_consults = this.getListPostFinishCosunsult(end_consult)
          break
        default:
          select_consults = this.getListPostFinishCosunsult(this.followType)
      }
      return select_consults
    },
    save() {
      const _self = this
      console.log('Fecha antes de guardar:', _self.consultData.proposed_date);
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          _self.consultData.proposed_date = _self.consultData.proposed_date || new Date();
          _self.$root.$emit('save-order', _self.consultData)
          _self.$root.$emit('bv::hide::modal', 'modal-medical-consult')
        }
      })
    },
  },
}
</script>
<style>
.left_title {
  text-decoration: underline;
  font-weight: bolder !important;
}

.hidden-div {
  display: none;
}
</style>
