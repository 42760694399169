export default [
    {
      path: '/reports/',
      name: 'apps-reports',
      component: () => import('@/views/reports/report/index.vue'),
      meta: {
        pageTitle: 'Reportes',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true,
          },
        ],
      },
    },
  ]