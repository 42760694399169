<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row class="d-flex">
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-start">
            <b-col
              cols="2"
              md="2"
            >
              <div
                id="v-pills-tab"
                class="nav flex-column nav-pills me-3"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  v-for="tab in tabs"
                  class="nav-link"
                  type="button"
                  :class="{'active':tab.isActive}"
                  role="tab"
                  data-toggle="tab"
                  @click.stop.prevent="setActive(tab)"
                >
                  {{ $t(ELEMENT_NAME + '.tab.' + tab.name) }}
                </button>
              </div>
            </b-col>
            <b-col
              cols="10"
              md="10"
            >
              <div
                id="v-pills-tabContent"
                class="tab-content"
              >
                <div
                  v-for="tab in tabs"
                  role="tabpanel"
                  class="tab-pane"
                  :class="{active:tab.isActive}"
                >
                  <patient-info-card v-if="tab.name == 'v-patient'" />
                  <case-info-card v-if="tab.name == 'v-case'" />
                  <digital-file-info-card v-if="tab.name == 'v-digital-file'" />
                  <logbook-info-card v-if="tab.name == 'v-logbook'" />
                  <other-cases-info-card v-if="tab.name == 'v-other-cases'" />
                  <support-document-card v-if="tab.name == 'v-support-document'" />
                  <first-date-card
                    v-if="tab.name == 'FIRST_DATE'"
                    :step="tab.step"
                    :element-data="elementData"
                  />
                  <follow-up-card
                    v-if="tab.name != 'FIRST_DATE' && tab.type == 'v-follow-up'"
                    :step="tab.step"
                    :element-data="elementData"
                  />
                </div>
              </div>
            </b-col>
          </div>
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '../storeModule'
import CaseInfoCard from './case.vue'
import PatientInfoCard from './patient.vue'
import DigitalFileInfoCard from './digital_file.vue'
import LogbookInfoCard from './logbook.vue'
import OtherCasesInfoCard from './other_cases.vue'
import FirstDateCard from './first_date.vue'
import FollowUpCard from './follow_up.vue'
import SupportDocumentCard from './support_document.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    CaseInfoCard,
    PatientInfoCard,
    LogbookInfoCard,
    OtherCasesInfoCard,
    DigitalFileInfoCard,
    FirstDateCard,
    FollowUpCard,
    SupportDocumentCard,
  },
  data() {
    return {
      elementData: {},
      caseData: {},
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: 'medical_history',
      avatar: '',
      tab: {},
      tabs: [],
      activeTab: {},
    }
  },
  async mounted() {
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.elementData = await this.fetchElement()
    this.caseData = await this.fetchCase()
    this.tabs = [
      {
        name: 'v-patient',
        id: 0,
        isActive: true,
      }, {
        name: 'v-case',
        id: 1,
        isActive: false,
      }, {
        name: 'v-digital-file',
        id: 2,
        isActive: false,
      }, {
        name: 'v-logbook',
        id: 3,
        isActive: false,
      }, {
        name: 'v-other-cases',
        id: 4,
        isActive: false,
      }, {
        name: 'v-support-document',
        id: 5,
        isActive: false,
      },
    ]
    let idx = 6
    let additionalCount = 0
    const ordinals = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FIFTH', 'SIXTH', 'SEVENTH', 'EIGHTH', 'NINTH', 'TENTH']

    this.caseData.process.steps.sort((a, b) => (a.id < b.id ? -1 : 1))
    for (const step of this.caseData.process.steps) {
      if (['FIRST_DATE', 'FIRST_FOLLOW', 'SECOND_FOLLOW', 'THIRD_FOLLOW', 'FOURTH_FOLLOW', 'ADDITIONAL', 'EVOLUTION'].includes(step.step_type.key)) {
        if (step.step_status.key === 'FINISHED') {
          let tabName = step.step_type.key
          if (step.step_type.key === 'ADDITIONAL') {
            tabName = `${ordinals[additionalCount]}_${step.step_type.key}`
            additionalCount++
          }
          this.tabs.push(
            {
              type: 'v-follow-up',
              name: tabName,
              id: idx++,
              isActive: false,
              step,
            },
          )
        }
      }
    }
    this.tabs.push(
      {
        name: 'v-return',
        id: idx,
        isActive: false,
        isBack: true,
      },
    )
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    setActive(tab) {
      if (tab.isBack) {
        this.$router.go(-1)
      }
      const self = this
      tab.isActive = true
      this.activeTab = tab
      this.tabs.forEach(tab => {
        if (tab.id !== self.activeTab.id) { tab.isActive = false }
      })
      if (['v-follow-up'].includes(tab.type)) {
        if(tab.name == 'FIRST_DATE'){
          this.$root.$emit('resize-first-date-stepper')
        } else{
          this.$root.$emit('resize-follow-up-stepper')
        }
      }
    },
    async fetchElement() {
      console.log(router.currentRoute.params)
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.patient_id })
        .then(response => {
          const elementData = response.data
          console.log("Leer esto: ", elementData)
          if (elementData.logo_id) {
            this.fetchAvatar(elementData.logo_id)
          }
          return elementData
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            return {}
          }
        })
    },
    async fetchCaseDate(step_id) {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_case_date`, { step_id }).then(async response => response.data).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchCase() {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_case`, { id: router.currentRoute.params.case_id }).then(async response => {
        const elementData = response.data
        for (const step of elementData.process.steps) {
          step.case_date = await this.fetchCaseDate(step.id)
        }
        return elementData
      }).catch(error => {
        if (error.response.status === 404) {
          return {}
        }
      })
    },
    fetchAvatar(logo_id) {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchImage`, { id: logo_id })
        .then(response => {
          this.avatar = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.logo)
            avatar = undefined
          }
        })
    },
  },
}
</script>

<style>

</style>
