<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="elementData === undefined">
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + ".search.error.fetching_single_data") }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + ".search.error.check") }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-' + RESOURCES_ELEMENT_NAME + '-list' }"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + ".search.error.list") }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + ".search.error.to_find_other") }}
      </div>
    </b-alert>

    <template v-if="Object.keys(elementData).length > 0">
      <element-edit-tab
        :new-element="newElement"
        :element-data="elementData"
        :case-type-list="caseTypeList"
        :case-status-list="caseStatusList"
        :case-tracking-type-list="caseTrackingTypeList"
        :arl-list="arlList"
        :document-type-list="documentTypeList"
        :boolean-list="booleanList"
        :boolean-n-a-list="booleanNAList"
        :country-list="countryList"
        :contact-type-list="contactTypeList"
        :department-list="departmentList"
        :city-company-list="cityCompanyList"
        :city-patient-list="cityPatientList"
        :supplier-type-list="supplierTypeList"
        :sex-list="sexList"
        :eps-list="epsList"
        :user-list="userList"
        :medical-consult-services-list="medicalConsultServicesList"
        :genre-list="genreList"
        :step-status-list="stepStatusList"
        :notification-medium-list="notificationMediumList"
        :step-type-list="stepTypeList"
        :employment-list="employmentList"
        :contact-status-list="contactStatusList"
        :date-type-list="dateTypeList"
        :relationship-list="relationshipList"
        :population-zone-list="populationZoneList"
        :element-avatar="avatar"
        :follow-type-list="followTypeList"
        :consult-type-list="caseTrackingTypeList"
        :list-url="get_list_url()"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        class="mt-2 pt-75"
      />
    </template>
  </div>
</template>

<script>
import { BAlert, BLink } from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import elementStoreModule from "../storeModule";
import ElementEditTab from "./EditCard.vue";

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: "",
      ELEMENT_APP_STORE_MODULE_NAME: "",
      newElement: true,
      elementData: {},
      caseTypeList: [],
      countryList: [],
      contactTypeList: [],
      departmentList: [],
      cityCaseList: [],
      cityCompanyList: [],
      cityPatientList: [],
      supplierTypeList: [],
      sexList: [],
      genreList: [],
      documentTypeList: [],
      employmentList: [],
      arlList: [],
      epsList: [],
      booleanNAList: [],
      medicalConsultServicesList: [],
      userList: [],
      caseStatusList: [],
      caseTrackingTypeList: [],
      booleanList: [],
      notificationMediumList: [],
      stepTypeList: [],
      stepStatusList: [],
      contactStatusList: [],
      dateTypeList: [],
      relationshipList: [],
      populationZoneList: [],
      followTypeList: [],
      avatar: "",
    };
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = "case";
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-list-rec-${this.RESOURCES_ELEMENT_NAME}`;
    const _self = this;
    this.$root.$on("add-file", async (file) => {
      _self.elementData.files.push(await _self.addFile(file));
    });
    // Register module
    await this.$store
      .dispatch('dataList/fetch_lists', { _country: 'Colombia' })
      .then()
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    const dataStore = await this.$store.getters['dataList/getDataList']({
      listState: [
        'services',
        'serviceType',
      ],
    })

    const internalServices = dataStore.services.map((services) => ({
      ...services,
      value: `${services.cups} ${services.description}`,
    }))

    _self.medicalConsultServicesList = internalServices.filter(
      (element) => element.service_type.key === '07'
    )
    console.log(_self.medicalConsultServicesList)

    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        elementStoreModule
      );
    this.administrationRouteList = await this.fetch_key_value(
      "administration_route"
    );
    this.booleanList = await this.fetch_key_value("boolean");
    this.booleanNAList = await this.fetch_key_value("booleanNA");
    this.caseTypeList = await this.fetch_key_value("case_type");
    this.countryList = await this.fetch_key_value("geography");
    this.contactTypeList = await this.fetch_key_value("contact_type");
    this.departmentList = await this.fetchDepartments();
    this.sexList = await this.fetch_key_value("sex");
    this.genreList = await this.fetch_key_value("genre");
    this.documentTypeList = await this.fetch_key_value("document_type");
    this.employmentList = await this.fetch_key_value("employment");
    this.arlList = await this.fetch_arls();
    this.epsList = await this.fetch_key_value("eps");
    this.caseTrackingTypeList = await this.fetch_key_value(
      "case_tracking_type"
    );
    this.caseStatusList = await this.fetch_key_value("case_status");
    this.userList = await this.fecth_user_list();
    this.supplierTypeList = await this.fetch_key_value("supplier_type");
    this.stepTypeList = await this.fetch_key_value("process_step");
    this.stepStatusList = await this.fetch_key_value("step_status");
    this.notificationMediumList = await this.fetch_key_value(
      "process_noti_medium"
    );
    this.contactStatusList = await this.fetch_key_value("try_contact_status");
    this.dateTypeList = await this.fetch_key_value("date_type");
    this.relationshipList = await this.fetch_key_value("relationship");
    this.populationZoneList = await this.fetch_key_value("population_zone");

    if (router.currentRoute.params.id !== undefined) {
      await this.fetchElement();
      if (this.elementData.id !== undefined) {
        this.cityCaseList = await this.fetchCities(
          this.elementData.department_id
        );
        this.cityCompanyList = await this.fetchCities(
          this.elementData.company.department_id
        );
      }
    } else {
      const case_status = this.caseStatusList.find(
        (element) => element.key == "RECEIVED"
      );
      this.elementData = {
        status: 1,
        accident_date: null,
        report_date: null,
        process: {
          steps: [],
        },
        case_status,
        case_status_id: case_status.id,
        source_positive_hiv: 0,
        source_positive_vhc: 0,
        source_positive_vhb: 0,
        source_positive_syphilis: 0,
        source_birthdate: null,
        priority_case: false,
        positive_hiv: 0,
        positive_vhc: 0,
        positive_vhb: 0,
        positive_syphilis: 0,
        patient: {
          status: 1,
          emails: [],
          guardians: [],
          contacts: [],
          birthdate: null,
        },
        company: {
          status: 1,
          guardians: [],
          contacts: [],
          headquarters: [],
          activation_date: "",
          inactivation_date: "",
          contract_end_date: "",
          company_type_key: "ENTERPRISE",
        },
      };
    }

    // if (this.elementData.process.audit_action == 'MIGRATION') {
    //   this.followTypeList = await this.fetch_key_value('follow_type')
    // } else {
    //   this.followTypeList = await this.fetch_key_value('case_tracking_type')
    // }
  },
  beforeDestroy() {
    this.$root.$off("add-file");
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME);
  },
  methods: {
    async addFile(file) {
      const fileData = new FormData();
      fileData.append("file", file);
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/addFile`, {
          fileData,
          case_id: this.elementData.id,
        })
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return undefined;
        });
    },
    async fetch_key_value(type) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_key_value`, {
          type,
        })
        .then((response) => {
          const list = response.data.items;
          list.unshift({ id: null, value: this.$t("list.defaultOption") });
          return list;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined;
          }
        });
    },
    async fetchDepartments() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchDepartments`)
        .then((response) => {
          const list = response.data.items;
          list.unshift({ id: null, value: this.$t("list.defaultOption") });
          return list;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fecth_user_list() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fecth_users`)
        .then((response) => {
          const list = response.data;
          list.unshift({ id: null, name: this.$t("list.defaultOption") });
          return list;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fetchCities(department_id) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCities`, {
          parent: department_id,
        })
        .then((response) => {
          const cityList = response.data.items;
          cityList.unshift({ id: null, value: this.$t("list.defaultOption") });
          return cityList;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fetchCitiesCompany() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCities`, {
          parent: this.elementData.company.department_id,
        })
        .then((response) => {
          const cityList = response.data.items;
          cityList.unshift({ id: null, value: this.$t("list.defaultOption") });
          this.cityCompanyList = cityList;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fetchNotificationConfig(step_id) {
      return store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_config_notification`,
          { step_id }
        )
        .then((response) => {
          console.log("response: ",response);
          const elementList = response.data;
          elementList.unshift({
            id: null,
            email_subject: this.$t("list.defaultOption"),
          });
          return elementList;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fetchPatient(document_type_id, identity) {
      const _self = this;
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_patient`, {
          document_type_id,
          identity,
        })
        .then((response) => {
          response = response.data;
          if (response && response.id) {
            _self.elementData.patient = response;
            if (_self.elementData.patient.avatar_id) {
              this.fetchAvatar(_self.elementData.patient.avatar_id);
            }
          } else {
            _self.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: _self.$t(
                  `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_patient.title`
                ),
                icon: "SaveIcon",
                variant: "danger",
                text: _self.$t(
                  `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_patient.failure`
                ),
              },
            });
          }
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_patient.title`
              ),
              icon: "SaveIcon",
              variant: "danger",
              text: `${_self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_patient.failure`
              )}\n${error.response.data.message}`,
            },
          });
        });
    },
    async fetchCompany(nit) {
      const _self = this;
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_company`, {
          nit,
        })
        .then(async (response) => {
          response = response.data;
          if (response && response.id) {
            _self.elementData.company = response;
            _self.cityCompanyList = await _self.fetchCities(
              _self.elementData.company.department_id
            );
          } else {
            _self.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: _self.$t(
                  `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_company.title`
                ),
                icon: "SaveIcon",
                variant: "danger",
                text: _self.$t(
                  `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_company.failure`
                ),
              },
            });
          }
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_company.title`
              ),
              icon: "SaveIcon",
              variant: "danger",
              text: `${_self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.search_company.failure`
              )}\n${error.response.data.message}`,
            },
          });
        });
    },
    async fetchPatientCities(department_id) {
      const _self = this;
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCities`, {
          parent: department_id,
        })
        .then((response) => {
          _self.patientCityList = response.data.items;
          _self.patientCityList.unshift({
            id: null,
            value: this.$t("list.defaultOption"),
          });
          return _self.patientCityList;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return [];
          }
        });
    },
    async fetch_arls() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_arls`, {})
        .then((response) => {
          const list = response.data.items;
          list.unshift({ id: null, name: this.$t("list.defaultOption") });
          return list;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined;
          }
        });
    },
    async fetchElement() {
      this.newElement = false;
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, {
          id: router.currentRoute.params.id,
        })
        .then(async response => {
          console.log("fetchElements: }", response.data)
          this.elementData = response.data;
          await Promise.all(this.elementData.process.steps.map(async step => {
            step.case_date = await this.fetchCaseDate(step.id);
          }));
          if (this.elementData.patient.avatar_id) {
            this.fetchAvatar(this.elementData.patient.avatar_id);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.elementData = undefined;
          }
        });
    },
    async fetchCaseDate(step_id) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_case_date`, {
          step_id,
        })
        .then(async (response) => response.data)
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined;
          }
        });
    },
    send_notification(noti_id, case_id) {
      const _self = this;
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/send_notification`, {
          noti_id,
          case_id,
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send_notification.title`
              ),
              icon: "SaveIcon",
              variant: "success",
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send_notification.success`
              ),
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.log(error);
          }
        });
    },
    fetchAvatar(avatar_id) {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchImage`, {
          id: avatar_id,
        })
        .then((response) => {
          this.avatar = response.data.img;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.info("404", this.avatar);
            avatar = undefined;
          }
        });
    },
    get_list_url() {
      let url = `apps-${this.RESOURCES_ELEMENT_NAME}-list`;
      if (router.currentRoute.params.origin != "none") {
        url = `apps-${this.RESOURCES_ELEMENT_NAME}-${router.currentRoute.params.origin}-list`;
      }
      return url;
    },
    order_elementData(){
      return this.elementData.process.steps
        .map((step) => {
          let newStep = { ...step};
          if (step.step_type.key === 'ADDITIONAL') {
            newStep.step_type.value = `Consulta adicional`
          }
          return newStep
        })
    },
    async save(elementData) {
      const method =
        this.ELEMENT_APP_STORE_MODULE_NAME +
        (this.newElement ? "/add_element" : "/save_element");
      const action = this.newElement ? "creation" : "update";
      const _self = this;
      this.order_elementData();
      return store
        .dispatch(method, { data: elementData })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`
              ),
              icon: "SaveIcon",
              variant: "success",
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`
              ),
            },
          });
          if (this.newElement) {
            const case_id = response.data.id;
            _self.$router.replace({
              name: `apps-${_self.RESOURCES_ELEMENT_NAME}-edit`,
              params: { id: case_id },
            });
          } else if (
            this.elementData?.process?.steps?.[0]?.contact_status?.key ===
            "FAILED"
          ) {
            _self.$router.replace({
              name: `apps-${_self.RESOURCES_ELEMENT_NAME}-list`,
            });
          } else {
            _self.$router.go(0);
          }
          return true;
        })
        .catch((error) => {
          console.log(error);
          _self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`
              ),
              icon: "SaveIcon",
              variant: "danger",
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`
              ),
            },
          });
          return false;
        });
    },
  },
};
</script>

<style></style>
