<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>

    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2 col-12">
            <b-row>
              <b-col cols="12">
                <span class="font-weight-bold">
                  {{ $t(RESOURCES_ELEMENT_NAME + (newElement ? ".section.create" : ".section.edit")) }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card-header>

        <b-card-body>
          <validation-observer
            ref="formObserver"
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                <!-- Prefijo -->
                <b-col cols="6">
                  <b-form-group>
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.prefix") }}</span>
                    <span class="text-danger">*</span>
                    <validation-provider
                      name="prefix"
                      rules="required|max:4"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="elementData.prefix"
                        maxlength="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Próximo Número -->
                <b-col cols="6">
                  <b-form-group>
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.next_number") }}</span>
                    <span class="text-danger">*</span>
                    <validation-provider
                      name="next_number"
                      rules="required|integer|min_value:1|max_value:999999"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="elementData.next_number"
                        type="number"
                        min="1"
                        max="999999"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Botones -->
              <b-row class="mt-2">
                <b-col class="d-flex justify-content-end">
                  <b-button
                    class="mr-2"
                    @click="router().go(-1)"
                  >
                    {{ $t('actions.back') }}
                  </b-button>

                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    {{ $t('actions.save') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAlert,
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, max, integer, min_value, max_value } from 'vee-validate/dist/rules'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementStoreModule from '../storeModule'

// Registrar las reglas de validación
extend('required', {
  ...required,
  message: 'Este campo es requerido'
})
extend('max', {
  ...max,
  message: 'El campo no puede tener más de {length} caracteres'
})
extend('integer', {
  ...integer,
  message: 'El valor debe ser un número entero'
})
extend('min_value', {
  ...min_value,
  message: 'El valor debe ser mayor o igual a {min}'
})
extend('max_value', {
  ...max_value,
  message: 'El valor debe ser menor o igual a {max}'
})

export default {
  name: 'CreditNoteForm',
  
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      elementData: {
        prefix: '',
        next_number: ''
      },
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      newElement: false,
      id: undefined,
    }
  },

  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'consecutiveCreditNote'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`

    this.id = router.currentRoute.params.id

    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)
    }

    if (this.id) {
      await this.fetchElement()
      this.newElement = false
    } else {
      this.newElement = true
    }
  },

  unmounted() {
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    }
  },

  methods: {
    router() {
      return router
    },

    fetchElement() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElement`, { id: this.id })
        .then(response => {
          this.elementData = response.data
        })
        .catch(error => {
          if (error.response?.status === 404) {
            this.elementData = undefined
          }
        })
    },

    async save() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/${this.newElement ? 'addElement' : 'editElement'}`
      const action = this.newElement ? 'creation' : 'update'

      try {
        await store.dispatch(method, this.elementData)
        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'SaveIcon',
            variant: 'success',
            text: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
          },
        })
        
        return true
      } catch (error) {
        console.error(error)
        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.$t(`${this.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`),
          },
        })
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>