<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined">
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">

      <b-row>
        <b-col>
          <h5 style="display: inline;">Paciente:</h5>
          {{ this.elementData.first_name }} {{ this.elementData.second_name }} {{ this.elementData.first_surname }} {{ this.elementData.second_surname }} ({{ this.elementData.document_type.key }}: {{ this.elementData.identity }})
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h5 style="display: inline;">Médico:</h5>
          {{ doctorName}} 
        </b-col>
      </b-row>

      <b-row v-if="step.case_date.medical_history_file_path">
        <b-col>
          <a
            id="view-report-button"
            class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
            :href="getUrl(step.case_date.medical_history_file_path)"
            target="_blank">
            {{ $t('actions.download_medical_history') }}
          </a>
          <!-- ? DETAILS -->
          <b-tooltip
            target="view-report-button"
            :title="$t('actions.download_medical_history')"
            triggers="hover"
            :noninteractive="true"
            fallback-placement="counterclockwise">
          </b-tooltip>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <first-date-form
            v-if="meet_uuid"
            ref="first_date_form"
            :meet-uuid="meet_uuid"
            :edition_disabled="true" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { BRow, BCol, BButton, BAlert, BLink, BTooltip } from 'bootstrap-vue'
import ElementStoreModule from '@/views/case/received/storeModule'
import ElementInfoCard from '@/views/case/received/view/InfoCard.vue'
import FirstDateForm from '@/views/meet/local/index.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    BTooltip,
    // Local Components
    ElementInfoCard,
    FirstDateForm,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: '',
      meet_uuid: null,
    }
  },
  mounted() {
    this.ELEMENT_NAME = 'case'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-ca-v-${this.ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.meet_uuid = this.step.case_date.meet.uuid

    this.$root.$on('resize-first-date-stepper', () => {
      this.$refs.first_date_form.$refs.wizard.handleResize()
    })
    console.log("elementData: ", this.step.case_date, this.elementData.doctor)
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  computed: {
    doctorName() {
      if (!this.elementData || !this.step || !this.step.case_date || !this.elementData.doctor) {
        return 'Doctor no disponible';
      }

      const doctorIndex = this.step.case_date.doctor_id;
      const doctors = this.elementData.doctor[0];
      console.log('Doctores: ',doctors)

      for (let i = 0; i < doctors.length; i++) {
        if (doctors[i].id === doctorIndex) {
          const nameParts = doctors[i].name.split(' - ');
          return nameParts[1] ? nameParts[1].trim() : 'Nombre no disponible';
        }
      }

    return 'Doctor no encontrado';
    },
  },
  methods: {
    getUrl(path) {
      var path = path.split(/[\/,\\]+/)
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
  },
}
</script>

<style></style>
