<template>
    <div class="powerbi-container">
      <iframe
        :src="embedUrl"
        width="100%"
        height="600px"
        frameborder="0"
        allowfullscreen="true"
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=1df87b60-e2bc-43e2-8759-7a75d6a04c68&autoAuth=true&ctid=b0e9bff2-d731-4d05-b328-6507452e3f7b",
      };
    },
  };
  </script>
  
  <style scoped>
  .powerbi-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  