<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2 col-12">
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="9"
                xl="9"
              >
                <span class="font-weight-bold">
                  {{ $t(RESOURCES_ELEMENT_NAME + (newElement ? ".section.create" : ".section.edit")) }}
                </span>
              </b-col>
            </b-row>
            <br>
          </div>
        </b-card-header>
        <b-card-body>
          <validation-observer
            ref="elementForm"
            #default="{ invalid }"
          >
            <b-form
              @submit.prevent="save"
            >
              <b-row>
                <b-col
                  cols="3"
                >
                  <b-form-group
                    label-for="term_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.debit") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="term"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.debit"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="3"
                >
                  <b-form-group
                    label-for="term_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.credit") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="term"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.credit"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="3"
                >
                  <b-form-group
                    label-for="term_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.iva") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="term"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.iva"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="3"
                >
                  <b-form-group
                    label-for="term_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.service_type") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="term"
                      rules="required"
                    >
                      <v-select
                        v-model="elementData.service_type_id"
                        :options="serviceTypeList"
                        label="value"
                        value="id"
                        :reduce="option => option.id"
                        :placeholder="$t('list.defaultOption')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-end">
                <b-button
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="router().go(-1)"
                >
                  {{ $t('actions.back') }}
                </b-button>

                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid"
                >
                  {{ $t('actions.save') }}
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BAlert, BCard, BCardHeader, BCardBody, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ElementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      elementData: undefined,
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      serviceTypeList: [],
      newElement: false,
      type: undefined,
      id: undefined,
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'serviceAccount'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`

    this.id = router.currentRoute.params.id

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    console.info('router.currentRoute.params.id', router.currentRoute.params.id)
    console.info('this.id', this.id)
    if (this.id) {
      await this.fetchElement()
      this.newElement = false
    } else {
      this.elementData = {
        service_types_id: null,
      }
      this.newElement = true
    }
    this.serviceTypeList = await this.fetchServiceTypes()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    router() {
      return router
    },
    async fetchServiceTypes() {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchServiceTypes`, { }).then(response => response.data.items).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElement`, { id: this.id })
        .then(response => {
          this.elementData = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    async save() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/addElement`
      const action = this.newElement ? 'creation' : 'update'
      const _self = this
      const data = { service_type_id: this.elementData.service_type_id, debit: this.elementData.debit, credit: this.elementData.credit, iva: this.elementData.iva }

      return store.dispatch(method, data).then(response => {
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'SaveIcon',
            variant: 'success',
            text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
          },
        })
        _self.$router.replace({ name: 'apps-billing-service-account' })
        return true
      }).catch(error => {
        console.info(error)
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'SaveIcon',
            variant: 'danger',
            text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`),
          },
        })
        return false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
